import {classes} from './FormLoader.st.css';
import React from 'react';

export enum FormLoaderDataHook {
  loader = 'FormLoaderDataHook.loader',
}

export const FormLoader = () => {
  return (
    <div className={classes.root} data-hook={FormLoaderDataHook.loader}>
      <div className={classes.first}></div>
      <div className={classes.second}></div>
      <div className={classes.third}></div>
    </div>
  );
};
