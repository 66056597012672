import React from 'react';
import {Card, Text, TextPriority} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes as pickupInfoBoxClasses} from '../PickupInfoBox/PickupInfoBox.st.css';
import {classes, st} from './ShippingInfoBox.st.css';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../common/constants';

export enum ShippingInfoBoxDataHook {
  root = 'ShippingInfoBox.root',
  instructionsTitle = 'ShippingInfoBox.instructionsTitle',
  instructionsDetails = 'ShippingInfoBox.instructionsDetails',
}

export interface ShippingInfoBoxProps {
  instructions?: string;
  insideTimeSelector?: boolean;
}

const ShippingInfoBoxInternal = ({instructions, insideTimeSelector}: ShippingInfoBoxProps) => {
  const localeKeys = useLocaleKeys();

  const {experiments} = useExperiments();
  const RemoveOpacityGetFunctions = experiments.enabled(SPECS.RemoveOpacityGetFunctions);

  const shippingInfoBoxClassName = () => {
    /* istanbul ignore else */
    /* istanbul ignore next */
    if (insideTimeSelector) {
      /* istanbul ignore next */
      if (RemoveOpacityGetFunctions) {
        return pickupInfoBoxClasses.insideTimeSelector;
      } /* istanbul ignore next */ else {
        return pickupInfoBoxClasses.insideTimeSelectorOld;
      }
    }
    return pickupInfoBoxClasses.root;
  };

  return instructions ? (
    <div className={shippingInfoBoxClassName()}>
      <Card
        data-hook={ShippingInfoBoxDataHook.root}
        className={st(
          classes.fontSize,
          RemoveOpacityGetFunctions
            ? pickupInfoBoxClasses.card
            : /* istanbul ignore next */ pickupInfoBoxClasses.cardOld
        )}>
        <Card.Container>
          <Text
            tagName={'h4'}
            data-hook={ShippingInfoBoxDataHook.instructionsTitle}
            priority={TextPriority.primary}
            className={st(pickupInfoBoxClasses.title)}>
            {localeKeys.checkout.deliveryMethod.deliveryInstructions.title()}
          </Text>
          <Text
            data-hook={ShippingInfoBoxDataHook.instructionsDetails}
            priority={TextPriority.secondary}
            className={st(pickupInfoBoxClasses.instructions)}>
            {instructions}
          </Text>
        </Card.Container>
      </Card>
    </div>
  ) : null;
};

export const ShippingInfoBox = React.memo(ShippingInfoBoxInternal);
