import React from 'react';
import {Text, Card, TextPriority} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes} from './PickupInfoBox.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {StepId} from '../../../types/checkoutApp.types';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../common/constants';

export enum PickupInfoBoxDataHook {
  root = 'PickupInfoBox.root',
  deliveryTimeDetails = 'PickupInfoBox.deliveryTimeDetails',
  instructionsTitle = 'PickupInfoBox.instructionsTitle',
  instructionsDetails = 'PickupInfoBox.instructionsDetails',
}

export interface PickupInfoBoxProps {
  instructions?: string;
  deliveryTime?: string;
  insideTimeSelector?: boolean;
}

const PickupInfoBoxInternal = ({insideTimeSelector, instructions, deliveryTime}: PickupInfoBoxProps) => {
  const localeKeys = useLocaleKeys();
  const {
    stepsManagerStore: {activeStep},
  } = useControllerProps();
  const shouldShowDeliveryTime = deliveryTime && activeStep.stepId === StepId.deliveryMethod;
  const {experiments} = useExperiments();
  const RemoveOpacityGetFunctions = experiments.enabled(SPECS.RemoveOpacityGetFunctions);

  const pickupInfoBoxClassName = () => {
    /* istanbul ignore else */
    /* istanbul ignore next */
    if (insideTimeSelector) {
      /* istanbul ignore next */
      if (RemoveOpacityGetFunctions) {
        return classes.insideTimeSelector;
      } /* istanbul ignore next */ else {
        return classes.insideTimeSelectorOld;
      }
    }
    return classes.root;
  };

  // eslint-disable-next-line no-nested-ternary
  return instructions ? (
    <div className={pickupInfoBoxClassName()}>
      <Card
        data-hook={PickupInfoBoxDataHook.root}
        className={RemoveOpacityGetFunctions ? classes.card : /* istanbul ignore next */ classes.cardOld}>
        <Card.Container>
          {shouldShowDeliveryTime && (
            <Text
              data-hook={PickupInfoBoxDataHook.deliveryTimeDetails}
              priority={TextPriority.secondary}
              className={classes.deliveryTime}>
              {deliveryTime}
            </Text>
          )}
          <Text
            tagName={'h4'}
            data-hook={PickupInfoBoxDataHook.instructionsTitle}
            priority={TextPriority.primary}
            className={classes.title}>
            {localeKeys.checkout.pickup_details_form.store_pickup_instructions_header()}
          </Text>
          <Text
            data-hook={PickupInfoBoxDataHook.instructionsDetails}
            priority={TextPriority.secondary}
            className={classes.instructions}>
            {instructions}
          </Text>
        </Card.Container>
      </Card>
    </div>
  ) : null;
};

export const PickupInfoBox = React.memo(PickupInfoBoxInternal);
