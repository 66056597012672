import React from 'react';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {DataExtendedFieldView} from '@wix/wixstores-client-storefront-sdk';

export enum ExtendedFieldsViewHooks {
  Root = 'ExtendedFieldsView.Root',
  FieldText = 'ExtendedFieldsView.FieldText',
}

const ExtendedFieldView = ({viewValue}: {viewValue: DataExtendedFieldView}) => {
  const localeKeys = useLocaleKeys();

  const getDisplayValue = () => {
    if (typeof viewValue.value === 'boolean') {
      return viewValue.value
        ? localeKeys.checkout.extended_fields_view.checkbox.true()
        : localeKeys.checkout.extended_fields_view.checkbox.false();
    }
    return viewValue.value;
  };

  return (
    <div data-hook={ExtendedFieldsViewHooks.FieldText}>
      {viewValue.label}: {getDisplayValue()}
    </div>
  );
};

export const ExtendedFieldsView = ({values}: {values: DataExtendedFieldView[]}) => {
  return (
    <div data-hook={ExtendedFieldsViewHooks.Root}>
      {values.map((viewValue) => (
        <ExtendedFieldView viewValue={viewValue} key={viewValue.label} />
      ))}
    </div>
  );
};
