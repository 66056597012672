import {ViolationsAndPlaceOrderButtonLocation} from '../../../types/checkoutApp.types';
import {PlaceOrderSectionDataHook} from '../CheckoutSteps/Steps/PaymentAndPlaceOrderStep/Components/PlaceOrder/PlaceOrder';
import {PlaceOrderFastFlowDataHook} from '../PlaceOrderFastFlow/PlaceOrderFastFlow';
import {SummaryDataHooks} from '../Summary/Summary';

export const getViolationsAndPlaceOrderButtonDataHook = (location: ViolationsAndPlaceOrderButtonLocation): string => {
  switch (location) {
    case ViolationsAndPlaceOrderButtonLocation.paymentAndPlaceOrderStep:
      return PlaceOrderSectionDataHook.violations;
    case ViolationsAndPlaceOrderButtonLocation.summary:
      return SummaryDataHooks.violations;
    case ViolationsAndPlaceOrderButtonLocation.fastFlow:
      return PlaceOrderFastFlowDataHook.violations;
    /* istanbul ignore next */
    default:
      return '';
  }
};
