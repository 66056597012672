import {classes} from '../Components/CustomerDetailsOpen.st.css';
import {FIELDS_GAP} from '../../../../constants';
import {CustomerDetailsForm as ConsolidatedCustomerDetailsForm} from '../../../../Form/CustomerDetailsForm/CustomerDetailsForm';
import React, {useEffect} from 'react';
import {CustomerDetailsDataHook} from '../Components/CustomerDetailsOpen';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {useCustomerDetailsData} from '../WithCustomerDetailsData/WithCustomerDetailsData';
import {FormLoader} from '../../../../Form/FormLoader/FormLoader';
import {getCountryFromAddressFormValues} from '../../../../Form/form.utils';
import {TextField, Checkbox, Text} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../../../common/constants';
import {validateEmailAddress} from '../../../../Form/CustomerDetailsForm/CustomerDetailsForm.utils';

export const CustomerDetailsForm = ({
  emailValue,
  setEmailValue,
  isEmailValid,
  setIsEmailValid,
}: {
  emailValue?: string;
  setEmailValue?: React.Dispatch<React.SetStateAction<string>>;
  isEmailValid?: boolean;
  setIsEmailValid?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    formsStore: {areFormsLoaded, doesFormHaveSubscription},
    checkoutStore: {checkout},
    memberStore: {isMember, currentUserEmail},
    checkoutSettingsStore: {checkoutSettings},
    checkboxesStore: {toggleIsSubscriptionCheckboxChecked, isSubscriptionCheckboxChecked},
  } = useControllerProps();

  const {customerDetailsFormData, updateContactCountry} = useCustomerDetailsData();
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();

  const country = getCountryFromAddressFormValues(customerDetailsFormData.formValues);
  const shouldShowDisabledEmail =
    isMember &&
    experiments.enabled(SPECS.UseNewSubscriptionCheckboxInCustomerDetailsForm) &&
    doesFormHaveSubscription &&
    (currentUserEmail || checkout.buyerInfo.email);
  const shouldTestNewSubscriptionCheckboxLocation =
    experiments.enabled(SPECS.TestNewSubscriptionCheckboxLocation) &&
    !isMember &&
    checkoutSettings.isSubscriptionEnabled;

  useEffect(
    () => {
      void updateContactCountry(country);
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [country]
  );

  const handleEmailChange = (email: string) => {
    setEmailValue?.(email);
    setIsEmailValid?.(validateEmailAddress(email));
  };

  if (!areFormsLoaded) {
    return <FormLoader />;
  }

  return (
    <div
      data-hook={CustomerDetailsDataHook.customerDetailsForm}
      className={classes.forms}
      style={{
        gap: `${FIELDS_GAP}px`,
      }}>
      {shouldShowDisabledEmail && (
        <TextField
          data-hook={CustomerDetailsDataHook.disabledMemberEmail}
          className={classes.disabledEmail}
          label={localeKeys.ecom.email.label()}
          disabled
          value={currentUserEmail ?? checkout.buyerInfo.email}
        />
      )}
      {shouldTestNewSubscriptionCheckboxLocation && (
        <>
          <TextField
            data-hook={CustomerDetailsDataHook.temporaryEmailForSubscriptionABTest}
            className={classes.email}
            label={localeKeys.ecom.email.label()}
            value={emailValue}
            required
            error={!isEmailValid}
            errorMessage={'Enter an email address like example@mysite.com.'}
            newErrorMessage={true}
            onChange={(event) => handleEmailChange(event.target.value)}
          />
          <Checkbox
            data-hook={CustomerDetailsDataHook.subscriptionCheckbox}
            label={
              <Text data-hook={CustomerDetailsDataHook.subscriptionLabel}>
                {localeKeys.checkout.subscription.checkbox()}
              </Text>
            }
            checked={isSubscriptionCheckboxChecked}
            onChange={({checked}) => toggleIsSubscriptionCheckboxChecked(checked)}
          />
        </>
      )}
      <ConsolidatedCustomerDetailsForm {...customerDetailsFormData} />
    </div>
  );
};
