import React from 'react';
import {classes} from './ViolationsAndPlaceOrderButton.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {ViolationsAndPlaceOrderButtonLocation} from '../../../types/checkoutApp.types';
import {PlaceOrderButton} from '../PlaceOrderButton/PlaceOrderButton';
import {Violations} from '../Violations/Violations';
import {getViolationsAndPlaceOrderButtonDataHook} from './ViolationsAndPlaceOrderButton.utils';

export interface ViolationsAndPlaceOrderButtonProps {
  location: ViolationsAndPlaceOrderButtonLocation;
  checkboxesRef?: React.RefObject<HTMLDivElement>;
}

export const ViolationsAndPlaceOrderButton = ({location, checkboxesRef}: ViolationsAndPlaceOrderButtonProps) => {
  const {
    checkoutStore: {shouldShowViolations},
  } = useControllerProps();
  const dataHook = getViolationsAndPlaceOrderButtonDataHook(location);
  const className = location === ViolationsAndPlaceOrderButtonLocation.summary ? classes.rootMobile : classes.root;

  return (
    <div className={className}>
      {shouldShowViolations && <Violations dataHook={dataHook} />}
      <div className={classes.placeOrderButtonMobile}>
        <PlaceOrderButton checkboxesRef={checkboxesRef} />
      </div>
    </div>
  );
};
