import React, {useState} from 'react';
import {TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import ChevronDown from 'wix-ui-icons-common/on-stage/ChevronDown';
import ChevronUp from 'wix-ui-icons-common/on-stage/ChevronUp';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes} from './CollapsibleSection.st.css';

export enum CollapsibleSectionDataHook {
  collapseExpandButton = 'CollapsibleSectionDataHook.collapseExpandButton',
}

export interface CollapsibleSectionProps {
  initialOpen?: boolean;
  children: React.ReactNode;
  dataHook?: string;
}

export const CollapsibleSection = ({initialOpen, children, dataHook}: CollapsibleSectionProps) => {
  const [isOpen, setIsOpen] = useState(initialOpen ?? false);
  const localeKeys = useLocaleKeys();

  return (
    <div className={classes.root} data-hook={dataHook}>
      {isOpen && children}
      <TextButton
        data-hook={CollapsibleSectionDataHook.collapseExpandButton}
        className={classes.collapseButton}
        suffixIcon={isOpen ? <ChevronUp /> : <ChevronDown />}
        priority={TextButtonPriority.secondary}
        onClick={() => setIsOpen(!isOpen)}>
        {isOpen
          ? localeKeys.checkout.collapsible_section.less_details()
          : localeKeys.checkout.collapsible_section.more_details()}
      </TextButton>
    </div>
  );
};
