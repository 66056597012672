import {Text, TextPriority} from 'wix-ui-tpa/cssVars';
import {classes} from './BillingAddressTitle.st.css';
import React from 'react';
import {useLocaleKeys} from '../../../../../../../locale-keys/LocaleKeys';

export enum BillingAddressTitleDataHook {
  title = 'BillingAddressTitle.title',
}

export const BillingAddressTitle = () => {
  const localeKeys = useLocaleKeys();

  return (
    <div>
      <Text
        tagName={'h2'}
        priority={TextPriority.primary}
        data-hook={BillingAddressTitleDataHook.title}
        className={classes.billingAddressTitle}>
        {localeKeys.checkout.billing_information.title()}
      </Text>
    </div>
  );
};
