import React from 'react';
import {SectionNotification} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';
import {DeliveryMethodSectionDataHook} from '../DeliveryMethodSection';
import {useControllerProps} from '../../Widget/ControllerContext';
import {useExperiments} from '@wix/yoshi-flow-editor';

import {SPECS} from '../../../../common/constants';

export const UnsupportedShippingDestinationError = () => {
  const {experiments} = useExperiments();
  const localeKeys = useLocaleKeys();
  const {checkoutStore} = useControllerProps();
  const {externalCarrierError, hasShippingZipCodeError} = checkoutStore.checkout.errors;
  const shouldShowErrorForMissingZipcode = experiments.enabled(SPECS.ShowErrorForMissingZipcode);
  const getErrorMessage = (): string => {
    if (externalCarrierError) {
      return externalCarrierError?.data?.description;
    } else if (shouldShowErrorForMissingZipcode && hasShippingZipCodeError) {
      return localeKeys.checkout.deliveryMethod.error.missingZipCode();
    } else {
      return localeKeys.checkout.deliveryMethod.error.unsupportedRegion();
    }
  };

  return (
    <SectionNotification data-hook={DeliveryMethodSectionDataHook.noShippingMessage} type="error">
      <SectionNotification.Icon icon={<ErrorIcon />} />
      <SectionNotification.Text>{getErrorMessage()}</SectionNotification.Text>
    </SectionNotification>
  );
};
