import React, {useState} from 'react';
import {classes} from './Summary.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {TotalsSection} from './TotalsSection/TotalsSection';
import {CouponInput} from './CouponInput/CouponInput';
import {GiftCardInput} from './GiftCardInput/GiftCardInput';
import {FoldableSummarySection} from '../../../common/components/FoldableSummarySection/FoldableSummarySection';
import {SummaryTitle} from './SummaryTitle/SummaryTitle';
import {LineItemSection} from './LineItemSection/LineItemSection';
import {BiFoldableSummaryAction, BiMobilePosition} from '../../../domain/utils/bi.util';
import {isLineItemErrorViolationExist} from './LineItem/LineItem.utils';
import {getTotal} from '../../../domain/utils/total.util';

export const TopMobileSummary = () => {
  const {
    checkoutStore: {
      shouldShowGiftCardSection,
      shouldShowCouponSection,
      onMobileFoldableSummaryToggle,
      checkout,
      shouldShowViolations,
      hasPartialOutOfStockError,
    },
  } = useControllerProps();

  const shouldShowErrorIcon = Boolean(
    !!checkout.errors.noItemsError ||
      !!checkout.errors.couponError ||
      !!checkout.errors.giftCardError ||
      (shouldShowViolations && isLineItemErrorViolationExist(checkout.violations)) ||
      hasPartialOutOfStockError
  );

  const total = getTotal(checkout);

  const [isFoldableSummarySectionOpen, setIsFoldableSummarySectionOpen] = useState(false);
  const toggleFoldableSummarySection = () => {
    const isSummaryOpen = !isFoldableSummarySectionOpen;
    setIsFoldableSummarySectionOpen(isSummaryOpen);
    onMobileFoldableSummaryToggle(BiMobilePosition.top, {
      origin: 'top',
      action: isSummaryOpen ? BiFoldableSummaryAction.open : BiFoldableSummaryAction.close,
    });
  };

  return (
    <>
      <FoldableSummarySection
        onClick={toggleFoldableSummarySection}
        isOpen={isFoldableSummarySectionOpen}
        shouldShowErrorIcon={shouldShowErrorIcon}
        total={total}
      />
      {isFoldableSummarySectionOpen && (
        <div className={classes.topSummaryWithFoldableSection}>
          <SummaryTitle shouldShowNumberOfOrders={false} />
          <LineItemSection />
          <div className={classes.couponAndGiftCard}>
            {shouldShowCouponSection && <CouponInput mobilePosition={BiMobilePosition.top} />}
            {shouldShowGiftCardSection && <GiftCardInput mobilePosition={BiMobilePosition.top} />}
          </div>
          <TotalsSection />
        </div>
      )}
    </>
  );
};
