import React from 'react';
import {ContactAndAddressSummary} from '../../../../ContactAndAddressSummary/ContactAndAddressSummary';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {CustomerDetailsStepDataHook} from '../CustomerDetailsStep';
import {classes} from './CustomerDetailsCollapsed.st.css';

export const CustomerDetailsCollapsed = () => {
  const {
    checkoutStore: {checkout, isShippingFlow, extendedFieldsView},
  } = useControllerProps();

  const address = isShippingFlow ? checkout.shippingDestination?.address : undefined;

  return (
    <div data-hook={CustomerDetailsStepDataHook.collapsed} className={classes.root}>
      <ContactAndAddressSummary
        contact={checkout.shippingDestination?.contact}
        dataExtendedFields={extendedFieldsView}
        email={checkout.buyerInfo.email}
        customField={checkout.customField}
        address={address}
      />
    </div>
  );
};
