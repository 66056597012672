import React from 'react';
import {classes, st} from './LineItemSection.st.css';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {LineItemRow} from '../LineItem/LineItem';
import {useEnvironment} from '@wix/yoshi-flow-editor';

export enum LineItemSectionDataHooks {
  root = 'LineItemSectionDataHooks.root',
}

export const LineItemSection = () => {
  const localeKeys = useLocaleKeys();
  const {isMobile} = useEnvironment();
  const {
    checkoutStore: {checkout},
  } = useControllerProps();
  return (
    <section
      data-hook={LineItemSectionDataHooks.root}
      aria-label={localeKeys.checkout.orderSummary.fullSection.ariaLabel()}>
      <ul className={st(classes.itemsList, isMobile ? classes.itemsListMobile : undefined)}>
        {checkout.lineItems &&
          checkout.lineItems.map((lineItem) => (
            <LineItemRow lineItem={lineItem} key={lineItem.id} useLayoutForLongPrice={checkout.hasItemsWithLongPrice} />
          ))}
      </ul>
    </section>
  );
};
