import React from 'react';
import {classes} from './MemberDetailsSummary.st.css';
import {ContactAndAddressSummary} from '../../ContactAndAddressSummary/ContactAndAddressSummary';
import {TextButton} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useMemberDetailsData} from '../WithMemberDetailsData';
import {CustomFieldModel} from '../../../../domain/models/checkout/CustomField.model';
import {useControllerProps} from '../../Widget/ControllerContext';
import {BiAddressActionOrigin} from '../../../../domain/utils/bi.util';

export enum MemberDetailsSummaryDataHook {
  root = 'MemberDetailsSummary.root',
  editButton = 'MemberDetails.editButton',
}

export const MemberDetailsSummary = ({customField}: {customField?: CustomFieldModel}) => {
  const localeKeys = useLocaleKeys();
  const {setEditMode, areMemberDetailsValid, editMode, selectedAddressesService} = useMemberDetailsData();
  const {
    memberStore: {reportEditAddressClicked},
    checkoutStore: {checkout},
    stepsManagerStore: {activeStep},
  } = useControllerProps();

  const shouldShowEditButton = !editMode && areMemberDetailsValid;

  return (
    <div className={classes.container} data-hook={MemberDetailsSummaryDataHook.root}>
      <ContactAndAddressSummary
        contact={selectedAddressesService?.contact}
        address={selectedAddressesService?.address}
        customField={customField}
      />
      {shouldShowEditButton && (
        <TextButton
          key={'textButton'}
          data-hook={MemberDetailsSummaryDataHook.editButton}
          className={classes.editTitle}
          onClick={() => {
            setEditMode(true);
            reportEditAddressClicked({
              checkout,
              addressServiceId: selectedAddressesService?.addressesServiceId,
              stage: activeStep.stepId,
              origin: BiAddressActionOrigin.UserIntent,
            });
          }}>
          {localeKeys.checkout.edit()}
        </TextButton>
      )}
    </div>
  );
};
