import React from 'react';
import {DeliveryMethodStepDataHook} from '../DeliveryMethodStep';
import {classes} from './DeliveryMethodCollapsed.st.css';
import {Text, TextPriority} from 'wix-ui-tpa/cssVars';
import {cleanAddress} from '../../../../../../domain/utils/cleanAddress';
import {PickupInfoBox} from '../../../../PickupInfoBox/PickupInfoBox';
import {ShippingInfoBox} from '../../../../ShippingInfoBox/ShippingInfoBox';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {AppliedCouponModel} from '../../../../../../domain/models/checkout/AppliedCoupon.model';

export enum DeliveryMethodCollapsedDataHook {
  root = 'DeliveryMethodCollapsed.root',
  pickupDetails = 'DeliveryMethodCollapsed.pickupDetails',
  deliveryName = 'DeliveryMethodCollapsed.deliveryName',
  deliveryTime = 'DeliveryMethodCollapsed.deliveryTime',
  deliveryPrice = 'DeliveryMethodCollapsed.deliveryPrice',
}

const isFreeShippingCoupon = (coupon: AppliedCouponModel): boolean => coupon.couponType === 'FreeShipping';

export const DeliveryMethodCollapsed = () => {
  const {
    checkoutStore: {checkout},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  const pickupAddress = checkout.selectedShippingOption?.pickupAddress;
  const deliveryTimeText = checkout.selectedShippingOption?.hasTimeSlots
    ? checkout.selectedShippingOption?.deliveryTimeSlot
    : checkout.selectedShippingOption?.deliveryTime;

  const getPriceText = () => {
    if (
      checkout.priceSummary.shipping.amount === 0 ||
      (checkout.appliedCoupon && isFreeShippingCoupon(checkout.appliedCoupon))
    ) {
      return localeKeys.checkout.order_summary.totals.free();
    }
    return checkout.priceSummary.shipping.formattedAmount;
  };

  return (
    <div data-hook={DeliveryMethodStepDataHook.collapsed} className={classes.root}>
      <div data-hook={DeliveryMethodCollapsedDataHook.root} className={classes.container}>
        <div className={classes.details}>
          <Text priority={TextPriority.secondary}>
            <span data-hook={DeliveryMethodCollapsedDataHook.deliveryName}>
              {checkout.selectedShippingOption?.title}
            </span>
          </Text>
          {checkout.selectedShippingOption?.isPickup && (
            <Text priority={TextPriority.secondary} className={classes.subtitle}>
              <span data-hook={DeliveryMethodCollapsedDataHook.pickupDetails}>
                {cleanAddress(
                  localeKeys.checkout.delivery_method.pickup_address({
                    addressLine: pickupAddress?.addressLine,
                    city: pickupAddress?.city,
                    country: pickupAddress?.countryFullname,
                    subdivision: pickupAddress?.subdivisionFullname,
                    zipCode: pickupAddress?.postalCode,
                  })
                )}
              </span>
            </Text>
          )}
          {deliveryTimeText && (
            <Text
              priority={TextPriority.secondary}
              data-hook={DeliveryMethodCollapsedDataHook.deliveryTime}
              className={classes.subtitle}>
              {checkout.selectedShippingOption?.deliveryTime && (
                <span data-hook={DeliveryMethodCollapsedDataHook.deliveryTime}>{deliveryTimeText}</span>
              )}
            </Text>
          )}
        </div>
        <Text priority={TextPriority.secondary}>
          <span data-hook={DeliveryMethodCollapsedDataHook.deliveryPrice}> {getPriceText()}</span>
        </Text>
      </div>

      {checkout.selectedShippingOption?.isPickup ? (
        <PickupInfoBox
          instructions={checkout.selectedShippingOption?.instructions}
          deliveryTime={checkout.selectedShippingOption?.deliveryTime}
        />
      ) : (
        <ShippingInfoBox instructions={checkout.selectedShippingOption?.instructions} />
      )}
    </div>
  );
};
