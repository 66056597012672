import {FormHandle, FormValues} from '@wix/form-viewer';
import React, {useRef, useState} from 'react';
import {FormError, FormViewerHandle} from '@wix/form-viewer/widget';

export interface FormInstanceData {
  data: {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
    formErrors: FormError[];
    setFormErrors: React.Dispatch<React.SetStateAction<FormError[]>>;
    formRef: React.RefObject<FormHandle>;
  };
  isValid: () => Promise<boolean>;
  isRendered: () => boolean;
}

export function useFormInstance(initialData: FormValues | (() => FormValues)): FormInstanceData {
  const [formValues, setFormValues] = useState<FormValues>(initialData);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const formRef = useRef<FormViewerHandle>(null);

  const isValid = async () => {
    if (formRef.current) {
      return formRef.current.validate();
    }

    return true;
  };

  return {
    data: {
      formValues,
      setFormValues,
      formErrors,
      setFormErrors,
      formRef,
    },
    isValid,
    isRendered: () => !!formRef.current,
  };
}
