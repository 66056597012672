import React from 'react';
import {StatesButton, StatesButtonStates, ThreeDotsLoader, ButtonSize} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../Widget/ControllerContext';
import {classes} from './PlaceOrderButton.st.css';
import {usePaymentsApi} from '../../../common/components/WithPaymentsApi/WithPaymentsApi';
import {ICashierPaymentsApi} from '@wix/cashier-payments-widget';
import {useFunctionResultObservation} from '@wix/function-result-observation';
import {useFedopsLogger, useErrorMonitor, useExperiments, usePanorama} from '@wix/yoshi-flow-editor';
import {FedopsInteractions} from '../constants';
import {isAPaymentError, PaymentError} from '../../../types/payment.types';
import {CheckoutErrorCode} from '../../../domain/utils/errors';
import {toMonitorError} from '../../../domain/utils/errorMonitor.utils';
import {StepId} from '../../../types/checkoutApp.types';
import {useBillingData} from '../CheckoutSteps/Steps/PaymentAndPlaceOrderStep/WithBillingData';
import {useMemberDetailsData} from '../MemberDetails/WithMemberDetailsData';
import {UsePlaceOrderButtonState} from '../CheckoutSteps/Steps/PaymentAndPlaceOrderStep/UsePlaceOrderButtonState';
import {SPECS} from '../../../common/constants';

export const PlaceOrderButtonDataHook = 'place-order-button';

export const PlaceOrderButton = ({checkboxesRef}: {checkboxesRef?: React.RefObject<HTMLDivElement>}) => {
  const {experiments} = useExperiments();
  const localeKeys = useLocaleKeys();
  const errorMonitor = useErrorMonitor();

  const {paymentsApi, activePaymentId} = usePaymentsApi();
  const {
    navigationStore: {navigateToThankYouPage, isDonationsTheme},
    checkoutStore,
    paymentStore,
    deliveryMethodStore: {beforePlaceOrderClicked},
    checkboxesStore: {areCheckoutCheckboxesValid, isSubscriptionCheckboxChecked, setWasFormSubmitted},
    stepsManagerStore: {activeStep},
    checkoutSettingsStore: {checkoutContent},
  } = useControllerProps();

  const {
    isPlaceOrderButtonDisabled,
    checkout,
    isFastFlow,
    setPlaceOrderPaymentError,
    isShippingFlow,
    onInvalidDetailsFormSubmit,
  } = checkoutStore;
  const {shouldRequirePayment} = paymentStore;

  const {withObservation} = useFunctionResultObservation();
  const clickPlaceOrderButton = withObservation(checkoutStore, 'clickPlaceOrderButton');
  const setPaymentAndBillingDetailsV2 = withObservation(checkoutStore, 'setPaymentAndBillingDetailsV2');
  const confirmPayment = withObservation(paymentStore, 'confirmPayment');
  const fedops = useFedopsLogger();
  const panorama = usePanorama();
  const {isFormValid, billingSameAsShipping, isShippingValidForBilling, getBillingFormDataForSubmit} = useBillingData();

  const {selectedAddressesServiceId} = useMemberDetailsData();

  const {
    placeOrderButtonState,
    shouldPlaceOrderButtonBeDisabled,
    setPlaceOrderButtonState,
    setShouldPlaceOrderButtonBeDisabled,
  } = UsePlaceOrderButtonState();

  const isOnPaymentAndPlaceOrderStep = activeStep.stepId === StepId.paymentAndPlaceOrder;

  const scrollToCheckboxes = () => {
    checkboxesRef?.current?.scrollIntoView();
  };

  const handleSubmit = async () => {
    if (!areCheckoutCheckboxesValid) {
      setWasFormSubmitted(true);
      scrollToCheckboxes();
      return;
    }

    setPlaceOrderButtonState(StatesButtonStates.IN_PROGRESS);
    setShouldPlaceOrderButtonBeDisabled(true);
    if (isOnPaymentAndPlaceOrderStep) {
      await validateAndSubmitPaymentAndPlaceOrder();
    } else {
      beforePlaceOrderClicked();

      const price = checkout.payNowTotalAfterGiftCard.amount;
      const detailsId = await getPaymentsDetailsId({
        paymentsApi,
        price,
        fedops,
        panorama,
        errorMonitor,
        setPlaceOrderPaymentError,
        shouldUsePanorama: experiments.enabled(SPECS.ShouldUsePanorama),
        isCardTokenizationCheckout: checkout.isCardTokenizationCheckout,
      });
      /* istanbul ignore next */
      if (isAPaymentError(detailsId)) {
        return;
      }
      const paymentResponse = await clickPlaceOrderButton(
        isSubscriptionCheckboxChecked,
        detailsId as string | undefined
      );

      await handlePaymentResponse(paymentResponse, price, checkout.isCardTokenizationCheckout);
    }
  };

  const validateAndSubmitPaymentAndPlaceOrder = async () => {
    beforePlaceOrderClicked();
    let hasUpdateSucceded = true;

    if (shouldRequirePayment) {
      const isBillingFormValid = await isFormValid();
      const setBillingSameAsShipping = isShippingFlow && billingSameAsShipping && isShippingValidForBilling;

      fedops.interactionStarted(FedopsInteractions.ValidatePaymentInteraction);
      if (experiments.enabled(SPECS.ShouldUsePanorama)) {
        panorama.transaction(FedopsInteractions.ValidatePaymentInteraction).start();
      }
      const {isValid: isPaymentValid} = (await paymentsApi?.validate()) ?? /* istanbul ignore next */ {};
      fedops.interactionEnded(FedopsInteractions.ValidatePaymentInteraction);
      if (experiments.enabled(SPECS.ShouldUsePanorama)) {
        panorama.transaction(FedopsInteractions.ValidatePaymentInteraction).finish();
      }
      if (!isPaymentValid || !isBillingFormValid) {
        onInvalidDetailsFormSubmit();
        setPlaceOrderButtonState(StatesButtonStates.IDLE);
        setShouldPlaceOrderButtonBeDisabled(isPlaceOrderButtonDisabled);
        return;
      }

      hasUpdateSucceded = await setPaymentAndBillingDetailsV2({
        ...getBillingFormDataForSubmit(),
        addressesServiceId: selectedAddressesServiceId,
        setBillingSameAsShipping,
        activePaymentId,
      });
    }

    if (hasUpdateSucceded) {
      const price = checkout.payNowTotalAfterGiftCard.amount;
      const detailsId = await getPaymentsDetailsId({
        paymentsApi,
        price,
        fedops,
        panorama,
        errorMonitor,
        setPlaceOrderPaymentError,
        shouldUsePanorama: experiments.enabled(SPECS.ShouldUsePanorama),
        isCardTokenizationCheckout: checkout.isCardTokenizationCheckout,
      });
      /* istanbul ignore next */
      if (isAPaymentError(detailsId)) {
        return;
      }
      const paymentResponse = await clickPlaceOrderButton(
        isSubscriptionCheckboxChecked,
        detailsId as string | undefined
      );

      await handlePaymentResponse(paymentResponse, price, checkout.isCardTokenizationCheckout);
    }
  };

  const handlePaymentResponse = async (
    paymentResponse:
      | {
          orderId?: string | null;
          subscriptionId?: string;
          paymentResponseToken?: string | null;
          paymentError?: PaymentError;
        }
      | undefined,
    price: number,
    isCardTokenizationCheckout?: boolean
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    if (paymentResponse?.paymentError?.failureDetails) {
      const errorMessage =
        /* istanbul ignore next */ (await paymentsApi?.localizeError?.(
          paymentResponse?.paymentError?.failureDetails
        )) ?? localeKeys.checkout.page.generalPaymentError.subtitle();
      setPlaceOrderPaymentError(
        {
          translatedError: errorMessage,
          status: paymentResponse.paymentError.status,
          failureDetails: paymentResponse?.paymentError?.failureDetails,
        } as PaymentError,
        CheckoutErrorCode.PAYMENT_ERROR
      );
      return;
    }
    if (paymentsApi && paymentResponse?.paymentResponseToken && (price > 0 || isCardTokenizationCheckout)) {
      try {
        await paymentsApi.continuePayment?.(paymentResponse.paymentResponseToken);
      } catch (e: unknown) {
        /* istanbul ignore next */
        errorMonitor.captureException(...toMonitorError(e, 'PlaceOrderButton continuePayment'));
        /* istanbul ignore next */
        const paymentError = e as PaymentError;
        /* istanbul ignore next */
        if (paymentError.status === 'BUYER_CANCELED') {
          setPlaceOrderButtonState(StatesButtonStates.IDLE);
          setShouldPlaceOrderButtonBeDisabled(isPlaceOrderButtonDisabled);
        } else {
          /* istanbul ignore next */
          setPlaceOrderPaymentError(paymentError, CheckoutErrorCode.CUSTOM_PAYMENT_ERROR);
        }
        /* istanbul ignore next */
        return;
      }
    }
    if (paymentResponse?.orderId || paymentResponse?.subscriptionId) {
      if (isFastFlow && paymentResponse.paymentResponseToken) {
        // https://github.com/wix-private/cashier-client/blob/master/packages/express-checkout-widget/docs/Payment%20confirmation.md#payment-confirmation
        const result = await confirmPayment({
          chargeResponseToken: paymentResponse.paymentResponseToken,
          newApi: true,
        });
        /* istanbul ignore next */
        if (result.clientStatus !== 'Pending') {
          void navigateToThankYouPage({
            orderId: paymentResponse.orderId || paymentResponse.subscriptionId,
            isSubscription: Boolean(paymentResponse?.subscriptionId),
          });
        }
        /* istanbul ignore next */
        return;
        /* istanbul ignore next */
      }
      void navigateToThankYouPage({
        orderId: paymentResponse.orderId || paymentResponse.subscriptionId,
        isSubscription: Boolean(paymentResponse?.subscriptionId),
      });
    }
  };

  const getPlaceOrderButtonIdleContent = (): string => {
    let placeOrderButtonIdleContent: string = '';
    if (checkout.payNowTotalAfterGiftCard.amount > 0) {
      const placeOrderAndPayText = isDonationsTheme
        ? localeKeys.checkout.donations.donateNow.button()
        : localeKeys.checkout.placeOrderPay.button();
      placeOrderButtonIdleContent = checkoutContent?.placeOrderPayButton ?? placeOrderAndPayText;
    } else {
      const placeOrderText = localeKeys.checkout.place_order.place_order_button();
      placeOrderButtonIdleContent = checkoutContent?.placeOrderButton ?? placeOrderText;
    }

    return placeOrderButtonIdleContent;
  };

  function getPlaceOrderButton() {
    return (
      <StatesButton
        className={classes.placeOrderButton}
        data-hook={PlaceOrderButtonDataHook}
        disabled={shouldPlaceOrderButtonBeDisabled}
        upgrade={true}
        size={ButtonSize.medium}
        onClick={() => {
          void handleSubmit();
        }}
        idleContent={getPlaceOrderButtonIdleContent()}
        state={placeOrderButtonState}
        inProgressContent={<ThreeDotsLoader className={classes.threeDotButton} />}
        wrapContent
      />
    );
  }
  return getPlaceOrderButton();
};

async function getPaymentsDetailsId({
  paymentsApi,
  price,
  fedops,
  panorama,
  errorMonitor,
  setPlaceOrderPaymentError,
  shouldUsePanorama,
  isCardTokenizationCheckout,
}: {
  paymentsApi?: ICashierPaymentsApi;
  price: number;
  fedops: ReturnType<typeof useFedopsLogger>;
  panorama: ReturnType<typeof usePanorama>;
  errorMonitor: ReturnType<typeof useErrorMonitor>;
  setPlaceOrderPaymentError: (paymentError: PaymentError, errorCode: string) => void;
  shouldUsePanorama?: boolean;
  isCardTokenizationCheckout?: boolean;
}): Promise<string | undefined | PaymentError> {
  if (!paymentsApi || (price === 0 && !isCardTokenizationCheckout)) {
    return;
  }
  fedops.interactionStarted(FedopsInteractions.InitializePaymentInPlaceOrderInteraction);
  if (shouldUsePanorama) {
    panorama.transaction(FedopsInteractions.InitializePaymentInPlaceOrderInteraction).start();
  }
  try {
    const initializeResponse = await paymentsApi?.initializePayment?.();
    fedops.interactionEnded(FedopsInteractions.InitializePaymentInPlaceOrderInteraction);
    if (shouldUsePanorama) {
      panorama.transaction(FedopsInteractions.InitializePaymentInPlaceOrderInteraction).finish();
    }
    return initializeResponse?.detailsId;
  } catch (e: unknown) {
    /* istanbul ignore next */
    errorMonitor.captureException(...toMonitorError(e, 'PlaceOrderButton getPaymentsDetailsId'));
    /* istanbul ignore next */
    const paymentError = e as PaymentError;
    /* istanbul ignore next */
    setPlaceOrderPaymentError(paymentError, CheckoutErrorCode.CUSTOM_PAYMENT_ERROR);
    /* istanbul ignore next */
    return paymentError;
  }
}
