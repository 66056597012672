import React from 'react';
import {Text} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {classes} from './DeliveryMethodFastFlow.st.css';
import {DeliveryMethodSection} from '../../DeliveryMethodSection/DeliveryMethodSection';

export enum DeliveryMethodFastFlowDataHook {
  root = 'DeliveryMethodFastFlow.root',
  title = 'DeliveryMethodFastFlow.title',
}

export const DeliveryMethodFastFlow = () => {
  const localeKeys = useLocaleKeys();
  const {checkoutStore} = useControllerProps();
  const {checkout, shouldRequireZipCode, shouldShowSubdivisionSelector} = checkoutStore;

  const shouldShowDeliveryMethodSection =
    checkout.hasShippableItems && !shouldShowSubdivisionSelector && !shouldRequireZipCode;

  if (!shouldShowDeliveryMethodSection) {
    return null;
  }

  return (
    <div data-hook={DeliveryMethodFastFlowDataHook.root} className={classes.root}>
      <Text tagName={'h3'} data-hook={DeliveryMethodFastFlowDataHook.title} className={classes.title}>
        {localeKeys.checkout.place_order_fast_flow.delivery_method_title()}
      </Text>
      <DeliveryMethodSection />
    </div>
  );
};
