import {DeliveryMethodEmpty} from './Components/DeliveryMethodEmpty';
import {DeliveryMethodOpen} from './Components/DeliveryMethodOpen';
import {DeliveryMethodCollapsed} from './Components/DeliveryMethodCollapsed';
import React, {useEffect, useRef} from 'react';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {StepImplementationProps, StepState} from '../../../../../types/checkoutApp.types';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {classes as commonClasses} from '../../../../../common/common.st.css';
import {DynamicStep, DynamicStepComponentProps} from '../../StepsManager/Components/DynamicStep';
import {StepHeader} from '../../StepsManager/Components/StepHeader';
import {SlotId} from '../../../../../domain/utils/slotId';
import {useSlotPlaceholder} from '@wix/widget-plugins-ooi';
import {CheckoutSlot} from '../../../CheckoutSlot';
import {classes} from './DeliveryMethodStep.st.css';

export enum DeliveryMethodStepDataHook {
  root = 'DeliveryMethodStep.root',
  header = 'DeliveryMethodStepDataHook.header',
  collapsed = 'DeliveryMethodStep.collapsed',
  open = 'DeliveryMethodStep.open',
  empty = 'DeliveryMethodStep.empty',
  continueButton = 'DeliveryMethodStep.continue',
}

const InternalDeliveryMethodStep = ({index}: DynamicStepComponentProps) => {
  const localeKeys = useLocaleKeys();
  const {
    stepsManagerStore: {stepsList},
  } = useControllerProps();

  const stepState = stepsList[index].state;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Placeholder, isEmpty] = useSlotPlaceholder(SlotId.DeliveryMethodStep);
  /* istanbul ignore next: test slot */
  const shouldShowDeliveryMethodStepSlot = !isEmpty;
  const sectionRootRef = useRef<HTMLDivElement>(null);

  const scrollToHeader = () => {
    sectionRootRef?.current?.scrollIntoView();
  };

  useEffect(
    () => {
      if (stepState === StepState.OPEN) {
        scrollToHeader();
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [stepState]
  );

  return (
    <>
      <StepHeader
        index={index}
        dataHook={DeliveryMethodStepDataHook.header}
        label={localeKeys.checkout.place_order_fast_flow.delivery_method_title()}
        ref={sectionRootRef}
      />
      {stepState === StepState.EMPTY && <DeliveryMethodEmpty />}
      {
        /* istanbul ignore next: test slot */
        stepState !== StepState.EMPTY && shouldShowDeliveryMethodStepSlot && (
          <CheckoutSlot className={classes.deliveryMethodStepPlaceHolder} slotId={SlotId.DeliveryMethodStep} />
        )
      }
      {stepState === StepState.OPEN && !shouldShowDeliveryMethodStepSlot && <DeliveryMethodOpen />}
      {stepState === StepState.COLLAPSED && !shouldShowDeliveryMethodStepSlot && <DeliveryMethodCollapsed />}
    </>
  );
};

export const DeliveryMethodStep = ({index}: StepImplementationProps) => {
  return (
    <DynamicStep
      index={index!}
      dataHook={DeliveryMethodStepDataHook.root}
      component={InternalDeliveryMethodStep}
      className={commonClasses.oneColumnViewPadding}
    />
  );
};
