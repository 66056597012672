import React from 'react';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {InputWithApply} from '../InputWithApply/InputWithApply';
import {classes} from './ZipCode.st.css';
import {MAX_ZIP_CODE_FIELD_LENGTH} from '../../constants';

export enum ZipCodeDataHook {
  root = 'ZipCode.root',
}

export const ZipCode = () => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {setZipCode},
  } = useControllerProps();

  const validateZipCode = (inputValue: string) => {
    return inputValue !== '';
  };

  const handleApplyButtonClicked = (inputValue: string) => {
    if (validateZipCode(inputValue)) {
      void setZipCode(inputValue);
    }
  };

  return (
    <div className={classes.zipcode}>
      <InputWithApply
        dataHook={ZipCodeDataHook.root}
        title={`* ${localeKeys.checkout.place_order_fast_flow.zip_code_title()}`}
        onApply={handleApplyButtonClicked}
        buttonText={localeKeys.checkout.place_order_fast_flow.zip_code.apply()}
        validateInput={validateZipCode}
        errorMessage={localeKeys.checkout.address_information.zip_validation_errors.invalid()}
        maxLength={MAX_ZIP_CODE_FIELD_LENGTH}
      />
    </div>
  );
};
