import React, {useState} from 'react';
import {Card, Text, TextButton} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {Trans} from 'react-i18next';
import {useControllerProps} from '../Widget/ControllerContext';
import {classes} from './MemberInfoBox.st.css';
import {LogoutDialog} from './LogoutDialog/LogoutDialog';

export enum MemberInfoBoxDataHook {
  root = 'MemberInfoBox.root',
  content = 'MemberInfoBoxDataHook.content',
  loginTextButton = 'MemberInfoBoxDataHook.loginTextButton',
  logoutTextButton = 'MemberInfoBoxDataHook.logoutTextButton',
}

export const MemberInfoBox = () => {
  return (
    <Card data-hook={MemberInfoBoxDataHook.root} className={classes.card}>
      <Card.Container>
        <MemberInfoBoxText />
      </Card.Container>
    </Card>
  );
};

const MemberInfoBoxText = () => {
  const {isMember} = useControllerProps().memberStore;
  return isMember ? Logout() : Login();
};

const Login = () => {
  const {t, i18n} = useTranslation();
  const localeKeys = useLocaleKeys();
  const {onLogin} = useControllerProps().navigationStore;

  return (
    <Text data-hook={MemberInfoBoxDataHook.content} className={classes.content}>
      <Trans
        i18n={i18n}
        t={t}
        i18nKey={localeKeys.checkout.membersInfoBox.login()}
        components={{
          1: (
            <TextButton
              key={'textButton'}
              data-hook={MemberInfoBoxDataHook.loginTextButton}
              className={classes.actionTextButton}
              onClick={() => void onLogin()}></TextButton>
          ),
        }}
      />
    </Text>
  );
};

const Logout = () => {
  const localeKeys = useLocaleKeys();
  const {currentUserEmail} = useControllerProps().memberStore;
  const {onLogoutDialogToggled} = useControllerProps().checkoutStore;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialogModal = () => {
    onLogoutDialogToggled(!isDialogOpen);
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <>
      <div className={classes.logoutContainer}>
        <Text data-hook={MemberInfoBoxDataHook.content} className={classes.content}>
          {localeKeys.checkout.membersInfoBox.loggedInMessage({email: currentUserEmail})}
        </Text>
        <TextButton
          key={'logoutTextButton'}
          data-hook={MemberInfoBoxDataHook.logoutTextButton}
          className={classes.actionTextButton}
          onClick={toggleDialogModal}>
          {localeKeys.checkout.membersInfoBox.logout()}
        </TextButton>
      </div>
      <LogoutDialog onClose={toggleDialogModal} isOpen={isDialogOpen} />
    </>
  );
};
