import React from 'react';
import {Checkbox, Text} from 'wix-ui-tpa/cssVars';
import {classes} from './BillingDetails/BillingDetails.st.css';
import {useBillingData} from '../WithBillingData';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {useMemberDetailsData} from '../../../../MemberDetails/WithMemberDetailsData';
import {ADD_NEW_ADDRESS_ID} from '../../../../constants';

export enum BillingSameAsShippingCheckboxDataHook {
  billingSameAsShippingToggle = 'BillingSameAsShippingCheckbox.billingSameAsShippingToggle',
  billingSameAsShippingLabel = 'BillingSameAsShippingCheckbox.billingSameAsShippingLabel',
}

export const BillingSameAsShippingCheckbox = () => {
  const {
    memberStore: {isMember},
  } = useControllerProps();
  const {setBillingSameAsShipping, billingSameAsShipping} = useBillingData();
  const {resetMemberDetailsState, selectedAddressesServiceId, editMode} = useMemberDetailsData();
  const localeKeys = useLocaleKeys();

  const handleOnChange = ({checked}: {checked: boolean}) => {
    setBillingSameAsShipping(checked);
    /* istanbul ignore else */
    if (isMember && (selectedAddressesServiceId === ADD_NEW_ADDRESS_ID || editMode)) {
      resetMemberDetailsState();
    }
  };
  return (
    <Checkbox
      className={classes.sameAsShippingToggle}
      data-hook={BillingSameAsShippingCheckboxDataHook.billingSameAsShippingToggle}
      label={
        <Text data-hook={BillingSameAsShippingCheckboxDataHook.billingSameAsShippingLabel}>
          {localeKeys.checkout.billing_information.billing_same_as_delivery_label()}
        </Text>
      }
      checked={billingSameAsShipping}
      onChange={handleOnChange}
    />
  );
};
