import React from 'react';
import {ViolationSeverity} from '../../../domain/models/checkout/Violation.model';
import {Violation} from './Violation';
import {classes} from './Violations.st.css';
import {useControllerProps} from '../Widget/ControllerContext';

export interface ViolationsProps {
  dataHook: string;
  isTopBanner?: boolean;
}

export const Violations = ({isTopBanner, dataHook}: ViolationsProps) => {
  const {
    checkoutStore: {topThreeViolations},
  } = useControllerProps();

  return topThreeViolations.length > 0 ? (
    <div data-hook={dataHook} className={classes.root}>
      {topThreeViolations.map((violation, index) => (
        <Violation
          key={index}
          severity={isTopBanner ? ViolationSeverity.warning : violation.severity}
          description={violation.description}
        />
      ))}
    </div>
  ) : null;
};
