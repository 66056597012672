import React from 'react';
import {Text, SectionNotification, TextButton} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../../../../Widget/ControllerContext';
import {useLocaleKeys} from '../../../../../../../locale-keys/LocaleKeys';
import {classes} from './AddShippingAddressSection.st.css';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {Trans} from 'react-i18next';

export enum AddShippingAddressSectionDataHook {
  root = 'AddShippingAddressSection.root',
  title = 'AddShippingAddressSection.title',
  sectionNotification = 'AddShippingAddressSection.SectionNotification',
  addAddressButton = 'AddShippingAddressSection.addAddressButton',
}

export const AddShippingAddressSection = () => {
  const {
    checkoutStore: {shouldShowAddShippingAddressSection, AddShippingAddressToPreselectedFlow},
    stepsManagerStore: {openStep},
  } = useControllerProps();
  const {t, i18n} = useTranslation();
  const localeKeys = useLocaleKeys();

  return shouldShowAddShippingAddressSection ? (
    <div data-hook={AddShippingAddressSectionDataHook.root} className={classes.root}>
      <div className={classes.title}>
        <Text data-hook={AddShippingAddressSectionDataHook.title}>
          {localeKeys.checkout.deliveryMethod.shippingAndDelivery.label()}
        </Text>
      </div>
      <SectionNotification data-hook={AddShippingAddressSectionDataHook.sectionNotification}>
        <SectionNotification.Text>
          <Trans
            i18n={i18n}
            t={t}
            i18nKey={localeKeys.checkout.deliveryMethodOptions.enterAddress.sectionNotification()}
            components={{
              1: (
                <TextButton
                  key={'textButton'}
                  data-hook={AddShippingAddressSectionDataHook.addAddressButton}
                  className={classes.actionTextButton}
                  onClick={() => {
                    AddShippingAddressToPreselectedFlow();
                    openStep(0);
                  }}
                />
              ),
            }}
          />
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  ) : null;
};
